import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Grid } from '@mui/material';

import ButtonAdvice from 'assets/images/buk/button_advice.svg';
import ButtonImproveDebts from 'assets/images/buk/button_improve_debts.svg';
import ButtonNewCredit from 'assets/images/buk/button_new_credit.svg';
import ButtonOtherTopic from 'assets/images/buk/button_other_topic.svg';
import DashboardIllustration from 'assets/images/buk/dashboard_illustration.svg';
import DashboardIllustrationMobile from 'assets/images/buk/dashboard_illustration_mobile.svg';
import PlayButton from 'assets/images/buk/play_button.svg';
import useIsMobile from 'hooks/useIsMobile';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { captureUserEvent } from 'utils/posthogUtils';

import styles from './BukDashboard.module.scss';
import ChatButton from './components/ChatButton';
import RequestsHistory from './components/RequestsHistory';

const BukDashboard = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  // get accessToken from query params
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('accessToken');

  useEffect(() => {
    if (accessToken) {
      dispatch(setAccessToken({ accessToken }));
    }
  }, [accessToken]);
  if (isMobile) {
    return (
      <Box className={styles.DashboardContainer}>
        <Box className={styles.Card} display="flex" flexDirection="column">
          <Box className={styles.DescriptionContainer}>
            <Box className={styles.Title}>
              <h1>¡Hola!</h1>
            </Box>
            <h2>Bienvenido a la plataforma de salud financiera de Buk en alianza con Relif</h2>
            <p>
              Acá podrás encontrar asesoría personalizada y soluciones para mejorar tu endeudamiento, ahorro, orden
              financiero y mucho más.
            </p>
            <Box className={styles.Footer}>
              <h3>Puedes elegir qué quieres hacer, para que podamos empezar a ayudarte!</h3>
            </Box>
          </Box>
          <Box className={styles.Illustration}>
            <img src={DashboardIllustrationMobile} alt="Dashboard Illustration" />
          </Box>
        </Box>
        <Grid container>
          <ChatButton imgSrc={ButtonAdvice} alt="Button Advice" text="Quiero asesoría sobre mi endeudamiento" />
          <ChatButton imgSrc={ButtonNewCredit} alt="Button New Credit" text="Quiero solicitar un nuevo crédito" />
          <ChatButton
            imgSrc={ButtonImproveDebts}
            alt="Button Improve Debts"
            text="Quiero mejorar mis créditos actuales"
          />
          <ChatButton
            imgSrc={ButtonOtherTopic}
            alt="Button Other Topic"
            text="Quiero asesorarme sobre otro tema financiero"
          />
        </Grid>
        <Box className={styles.Card}>
          <Box
            className={styles.PlayButton}
            onClick={() => {
              captureUserEvent('BukDashboard: Watch video');
            }}
          >
            <p>Ver video explicativo</p>
            <img src={PlayButton} alt="Play Button" />
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box className={styles.DashboardContainer}>
      <Grid container>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <Box className={`${styles.Card} ${styles.MainCard}`}>
                <Grid container>
                  <Grid item xs={4}>
                    <Box className={styles.Illustration}>
                      <img src={DashboardIllustration} alt="Dashboard Illustration" />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className={styles.DescriptionContainer}>
                      <Box className={styles.Title}>
                        <h1>¡Hola!</h1>
                      </Box>
                      <h2>Bienvenido a la plataforma de salud financiera de Buk en alianza con Relif</h2>
                      <p>
                        Acá podrás encontrar asesoría personalizada y soluciones para mejorar tu endeudamiento, ahorro,
                        orden financiero y mucho más.
                      </p>
                      <Box className={styles.Footer}>
                        <h3>Puedes elegir qué quieres hacer, para que podamos empezar a ayudarte!</h3>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} pr={4}>
                  <Box className={styles.Card}>
                    <Box
                      className={styles.PlayButton}
                      onClick={() => {
                        captureUserEvent('BukDashboard: Watch video');
                      }}
                    >
                      <p>Ver video explicativo</p>
                      <img src={PlayButton} alt="Play Button" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={8} className={styles.ButtonGrid} my={3}>
                  <Grid container>
                    <ChatButton
                      imgSrc={ButtonAdvice}
                      alt="Button Advice"
                      text="Quiero asesoría sobre mi endeudamiento"
                    />
                    <ChatButton
                      imgSrc={ButtonNewCredit}
                      alt="Button New Credit"
                      text="Quiero solicitar un nuevo crédito"
                    />
                    <ChatButton
                      imgSrc={ButtonImproveDebts}
                      alt="Button Improve Debts"
                      text="Quiero mejorar mis créditos actuales"
                    />
                    <ChatButton
                      imgSrc={ButtonOtherTopic}
                      alt="Button Other Topic"
                      text="Quiero asesorarme sobre otro tema financiero"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} px={2}>
          <RequestsHistory />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BukDashboard;
