import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import refinanceIcon from 'assets/images/buk/refinance_request_icon.svg';
import { useGetRefinanceStatusQuery } from 'services/api/user.api';

import styles from '../BukDashboard.module.scss';

interface Request {
  date: string;
  title: string;
  description: string;
}

const RequestsHistory = () => {
  const { data: refinanceStatus } = useGetRefinanceStatusQuery(null);
  const [requests, setRequests] = useState<Request[]>([]);

  useEffect(() => {
    if (refinanceStatus?.type === 'refinance') {
      let status = 'Esperando respuesta del banco';
      if (refinanceStatus?.step === 'hasAllOptions' || refinanceStatus?.step === 'hasSomeOptions') {
        status = 'Tienes ofertas disponibles';
      }
      setRequests([
        {
          date: refinanceStatus.requestDate.split('T')[0],
          title: 'Solicitud de refinanciamiento',
          description: status,
        },
      ]);
    }
  }, [refinanceStatus]);
  return (
    <Box className={styles.Card} height="100%">
      <Box className={styles.RequestsHistory}>
        <h2>Historial de solicitudes</h2>
        {requests.length === 0 && <p>No tienes solicitudes</p>}
        {requests.map((request) => (
          <Box key={`${request.title}, ${request.date}`} className={styles.RequestItem}>
            <Box className={styles.RequestIconContainer}>
              <img src={refinanceIcon} alt="Refinance request icon" />
            </Box>
            <Box className={styles.RequestInfoContainer}>
              <h3>{request.title}</h3>
              <p>{request.date}</p>
              <p>{request.description}</p>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default RequestsHistory;
