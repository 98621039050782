import Logo_Andes from 'assets/images/logos/caja-los-andes-blue.svg';
import Logo_Agrosuper from 'assets/images/logos/companies/agrosuper-azul.webp';
import Logo_Almacruz from 'assets/images/logos/companies/almacruz.webp';
import Logo_Besalco from 'assets/images/logos/companies/besalco.webp';
import Logo_Boetsch from 'assets/images/logos/companies/boetsch.webp';
import Logo_Buk from 'assets/images/logos/companies/buk.webp';
import Logo_Camanchaca from 'assets/images/logos/companies/camanchaca.webp';
import Logo_CChC from 'assets/images/logos/companies/camara-chilena-construccion.webp';
import Logo_Concha_y_Toro from 'assets/images/logos/companies/concha-y-toro-crop.webp';
import Logo_Datco from 'assets/images/logos/companies/datco.webp';
import Logo_Ebco from 'assets/images/logos/companies/ebco.webp';
import Logo_Elecmetal from 'assets/images/logos/companies/elecmetal.webp';
import Logo_Enaex from 'assets/images/logos/companies/enaex-black.webp';
import Logo_Falp from 'assets/images/logos/companies/falp.webp';
import Logo_Icafal from 'assets/images/logos/companies/icafal.webp';
import Logo_Italmod from 'assets/images/logos/companies/italmod.webp';
import Logo_Karcher from 'assets/images/logos/companies/karcher.webp';
import Logo_Lider_BCI from 'assets/images/logos/companies/lider-bci.webp';
import Logo_Fundacion_Lo_Barnechea from 'assets/images/logos/companies/logo-fundacion-lo-barnechea.webp';
import Logo_Grupo_Lagos from 'assets/images/logos/companies/logo-grupo-lagos-500.webp';
import Logo_Kaufmann from 'assets/images/logos/companies/logo_kaufmann.webp';
import Logo_Siena from 'assets/images/logos/companies/logo_siena.svg';
import Logo_Multi_X from 'assets/images/logos/companies/multi-x.webp';
import Logo_Oxxo from 'assets/images/logos/companies/oxxo.webp';
import Logo_Papa_Johns from 'assets/images/logos/companies/papa-johns.webp';
import Logo_Partamos_Por_Casa from 'assets/images/logos/companies/partamos-por-casa.webp';
import Logo_PaulaBenitez from 'assets/images/logos/companies/paula_venitez.webp';
import Logo_Probenefit from 'assets/images/logos/companies/probenefit.webp';
import Logo_Relif_Friends from 'assets/images/logos/companies/relif_friends.webp';
import Logo_Rotter_Krauss from 'assets/images/logos/companies/rotter-y-krauss.webp';
import Logo_Saam from 'assets/images/logos/companies/saam.webp';
import Logo_Simon_De_Cirene from 'assets/images/logos/companies/simon-de-cirene.webp';
import Logo_Sole_Orienta from 'assets/images/logos/companies/sole-orienta.webp';
import Logo_Terraservice from 'assets/images/logos/companies/terraservice.webp';
import Logo_TNS from 'assets/images/logos/companies/tns.webp';
import Logo_Tres_Montes_Lucchetti from 'assets/images/logos/companies/tres-montes-lucchetti.webp';
import Logo_Whatts from 'assets/images/logos/companies/whatts.webp';

type Company = {
  id: string;
  name: string;
  logo: string;
  masterCompanyLogo?: string;
  logoStyle?: React.CSSProperties;
};

const COMPANIES: Company[] = [
  {
    id: 'enaex',
    name: 'Enaex',
    logo: Logo_Enaex,
    logoStyle: {
      height: '150px',
    },
  },
  {
    id: 'agrosuper',
    name: 'Agrosuper',
    logo: Logo_Agrosuper,
    logoStyle: {
      height: '130px',
    },
  },
  {
    id: 'camanchaca',
    name: 'Camanchaca',
    logo: Logo_Camanchaca,
    logoStyle: { width: '242px', height: '60px', alignSelf: 'center' },
  },
  {
    id: 'cchc',
    name: 'Camara Chilena de la Construcción',
    logo: Logo_CChC,
    logoStyle: { height: '60px', alignSelf: 'center' },
  },
  {
    id: 'siena',
    name: 'Siena',
    logo: Logo_Siena,
    logoStyle: { height: '40px', alignSelf: 'center' },
  },
  {
    id: 'concha-y-toro',
    name: 'Concha y Toro',
    logo: Logo_Concha_y_Toro,
    logoStyle: { height: '70px', alignSelf: 'center' },
  },
  {
    id: 'grupo-lagos',
    name: 'Grupo Lagos',
    logo: Logo_Grupo_Lagos,
    logoStyle: { height: '72px' },
  },
  {
    id: 'besalco',
    name: 'Besalco',
    logo: Logo_Besalco,
    logoStyle: { width: '230px', alignSelf: 'center' },
  },
  {
    id: 'caja-los-andes',
    name: 'Caja Los Andes',
    logo: Logo_Andes,
    logoStyle: { height: '100px', alignSelf: 'center' },
  },
  {
    id: 'los-amigos-de-relif',
    name: 'Los Amigos de Relif',
    logo: Logo_Relif_Friends,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'kaufmann',
    name: 'Grupo Kaufmann',
    logo: Logo_Kaufmann,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  /* Pro Benefit companies */
  {
    id: 'probenefit',
    name: 'Probenefit',
    logo: Logo_Probenefit,
    logoStyle: { height: '60px', alignSelf: 'center' },
  },
  {
    id: 'sole-orienta',
    name: 'Sole Orienta',
    logo: Logo_Sole_Orienta,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'oxxo',
    name: 'Oxxo',
    logo: Logo_Oxxo,
    masterCompanyLogo: Logo_Sole_Orienta,
    logoStyle: { height: '120px', alignSelf: 'center' },
  },
  {
    id: 'rotter-y-krauss',
    name: 'Rotter & Krauss',
    logo: Logo_Rotter_Krauss,
    masterCompanyLogo: Logo_Sole_Orienta,
    logoStyle: { height: '60px', alignSelf: 'center' },
  },
  {
    id: 'almacruz',
    name: 'Almacruz',
    logo: Logo_Almacruz,
    masterCompanyLogo: Logo_Sole_Orienta,
    logoStyle: { height: '140px', alignSelf: 'center' },
  },
  {
    id: 'tns',
    name: 'TNS',
    logo: Logo_TNS,
    masterCompanyLogo: Logo_Sole_Orienta,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'lider-bci',
    name: 'Líder BCI',
    logo: Logo_Lider_BCI,
    masterCompanyLogo: Logo_Sole_Orienta,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'karcher',
    name: 'Kärcher',
    logo: Logo_Karcher,
    masterCompanyLogo: Logo_Sole_Orienta,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  /* End Pro Benefit companies */
  {
    id: 'buk',
    name: 'Buk',
    logo: Logo_Buk,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'saam',
    name: 'SAAM',
    logo: Logo_Saam,
    logoStyle: { height: '180px', alignSelf: 'center' },
  },
  {
    id: 'partamos-por-casa',
    name: 'Partamos por Casa',
    logo: Logo_Partamos_Por_Casa,
    logoStyle: { height: '120px', alignSelf: 'center' },
  },
  {
    id: 'fundacion-lo-barnechea',
    name: 'Fundación Lo Barnechea',
    logo: Logo_Fundacion_Lo_Barnechea,
    logoStyle: { height: '60px', alignSelf: 'center' },
  },
  {
    id: 'icafal',
    name: 'Icafal',
    logo: Logo_Icafal,
    logoStyle: { height: '140px', alignSelf: 'center' },
  },
  {
    id: 'rrss',
    name: 'Redes sociales', // Is not used
    logo: '',
  },
  {
    id: 'ebco',
    name: 'EBCO',
    logo: Logo_Ebco,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'italmod',
    name: 'Italmod',
    logo: Logo_Italmod,
    logoStyle: { height: '40px', alignSelf: 'center' },
  },
  {
    id: 'multi-x',
    name: 'Multi X',
    logo: Logo_Multi_X,
    logoStyle: { height: '35px', alignSelf: 'center' },
  },
  {
    id: 'simon-de-cirene',
    name: 'Simón de Cirene',
    logo: Logo_Simon_De_Cirene,
    logoStyle: { height: '70px', alignSelf: 'center' },
  },
  {
    id: 'terraservice',
    name: 'Terraservice',
    logo: Logo_Terraservice,
    logoStyle: { height: '40px', alignSelf: 'center' },
  },
  {
    id: 'boetsch',
    name: 'Boetsch',
    logo: Logo_Boetsch,
    logoStyle: { height: '50px', alignSelf: 'center' },
  },
  {
    id: 'papa-johns',
    name: "Papa John's",
    logo: Logo_Papa_Johns,
    logoStyle: { height: '50px', alignSelf: 'center' },
  },
  {
    id: 'tres-montes-lucchetti',
    name: 'Tres Montes Lucchetti',
    logo: Logo_Tres_Montes_Lucchetti,
    logoStyle: { height: '140px', alignSelf: 'center' },
  },
  {
    id: 'whatts',
    name: 'Whatts',
    logo: Logo_Whatts,
    logoStyle: { height: '60px', alignSelf: 'center' },
  },
  {
    id: 'elecmetal',
    name: 'Elecmetal',
    logo: Logo_Elecmetal,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'falp',
    name: 'FALP',
    logo: Logo_Falp,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'paula-benítez',
    name: 'Paula Benítez',
    logo: Logo_PaulaBenitez,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  {
    id: 'datco',
    name: 'Grupo Datco',
    logo: Logo_Datco,
    logoStyle: { height: '80px', alignSelf: 'center' },
  },
  // If you add a new company check that the id matches with the name in db
];

export default COMPANIES;
