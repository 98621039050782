import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Send from '@mui/icons-material/Send';
import { Grid, IconButton, TextField } from '@mui/material';

import Conversation from 'components/common/Conversation';
import useQuery from 'hooks/useQuery';
import Pusher from 'pusher-js';
import {
  useCreateChatMessageMutation,
  useGetChatUserMessagesQuery,
  useGetUserChatIdentifierQuery,
} from 'services/api/chat.api';

import ChatWrapper from './ChatWrapper';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const Chat = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const query = useQuery();
  const refetchFlagParam = query.get('refetchFlag');
  const firstMessage = query.get('firstMessage');
  const disableOnboarding = query.get('disableOnboarding');
  const parsedDisableOnboarding = disableOnboarding === 'true';

  const [messageToSend, setMessageToSend] = useState(firstMessage || '');
  const [createChatMessage] = useCreateChatMessageMutation();

  const { data: userChatIdentifier } = useGetUserChatIdentifierQuery(null);

  const { data: messages, refetch } = useGetChatUserMessagesQuery(null);
  const [refetchFlag, setRefetchFlag] = useState<number>(1);

  const handleSendMessage = () => {
    if (!userChatIdentifier || !messageToSend) {
      return;
    }
    createChatMessage({ message: messageToSend })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
        setMessageToSend('');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending demo chat message', error);
      });
  };

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching messages');
    });
  }, [refetchFlag]);

  useEffect(() => {
    if (refetchFlagParam) {
      setRefetchFlag((prev) => prev + 1);
    }
  }, [refetchFlagParam]);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, { cluster: 'sa1' });

    const channel = pusher.subscribe(`chat-${userChatIdentifier}`);
    channel.bind('new-message', (data: object) => {
      setRefetchFlag((prev) => prev + 1);
      console.log('New message', JSON.stringify(data)); // eslint-disable-line no-console
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${userChatIdentifier}`);
      pusher.disconnect();
    };
  }, [userChatIdentifier, setRefetchFlag]);

  useEffect(() => {
    if (firstMessage && messageToSend === firstMessage) {
      handleSendMessage();
    }
  }, [firstMessage, messageToSend]);

  // send message on enter
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSendMessage();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSendMessage]);

  return (
    <ChatWrapper companyIdentifier={companyIdentifier} disableOnboarding={parsedDisableOnboarding}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Conversation conversation={messages || []} scheduledMessages={[]} isClientView />
        <Grid container width="100%" p={2} justifyContent="space-between">
          <TextField
            id="outlined-basic-email"
            label="Mensaje"
            fullWidth
            multiline
            sx={{
              maxWidth: '80%',
              backgroundColor: '#fff',
              borderRadius: '40px',
              opacity: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': { borderRadius: '40px' },
            }}
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
          />
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            onClick={handleSendMessage}
          >
            <Send
              sx={{
                fontSize: '2.5rem',
              }}
            />
          </IconButton>
        </Grid>
      </div>
    </ChatWrapper>
  );
};

export default Chat;
