import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SecondaryBtn } from 'components/UI/Buttons';
import CloseableAlert from 'components/UI/CloseableAlert';
import InfoDialog from 'components/UI/InfoDialog';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import useQuery from 'hooks/useQuery';
import Error from 'pages/Error/ErrorAlert';
import { format } from 'rut.js';
import { usePasswordRecoveryMutation, useSignInUserMutation } from 'services/api/user.api';
import getReCaptchaToken from 'utils/getReCaptchaToken';
import { identifyPosthogUser } from 'utils/posthogUtils';
import validateEmail from 'utils/validations/validateEmail';

import { setAccessToken } from '../../redux/slices/accessToken.slice';
import styles from './SignIn.module.scss';

const SignIn: FC = () => {
  const [signIn, { data, isLoading, isSuccess, isError, error }] = useSignInUserMutation();
  const [
    passwordRecovery,
    {
      isLoading: isLoadingPasswordRecovery,
      data: dataPasswordRecovery,
      isError: isErrorPasswordRecovery,
      error: errorPasswordRecovery,
    },
  ] = usePasswordRecoveryMutation();

  const query = useQuery();
  const dispatch = useDispatch();
  const isRedirectFromUniqueFlow = query.get('uniqueFlowRedirect') === 'true';
  const passwordReset = query.get('reset');

  const [noEmailError, setNoEmailError] = React.useState(false);
  const [emailInput, setEmailInput] = React.useState('');
  const [rut, setRut] = React.useState(query.get('rut') || '');
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [signInWithRut, setSignInWithRut] = React.useState(isRedirectFromUniqueFlow);

  useEffect(() => {
    if (signInWithRut) {
      setEmailInput('');
    } else {
      setRut('');
    }
  }, [signInWithRut]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    if (!signInWithRut) {
      if (!validateEmail(emailInput)) {
        setInvalidEmail(true);
        return;
      }
    }
    const recaptchaToken = await getReCaptchaToken();
    const signInBody = {
      recaptchaToken,
      email: signInWithRut ? '' : emailInput,
      password: formData.get('password')?.toString() || '',
      rut: signInWithRut ? rut : '',
    };
    await signIn(signInBody);
  };

  const handlePasswordRecovery = () => {
    if (rut) {
      passwordRecovery({ rut }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
      return;
    }
    if (emailInput) {
      setNoEmailError(false);
      if (!validateEmail(emailInput)) {
        setInvalidEmail(true);
        return;
      }
      passwordRecovery({ email: emailInput }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    } else {
      setNoEmailError(true);
    }
  };

  const emailInputHelperText = () => {
    if (noEmailError) {
      return 'Por favor, ingresa un email para recuperar tu contraseña';
    }
    if (invalidEmail) {
      return 'Por favor, ingresa un email válido';
    }
    return '';
  };

  useEffect(() => {
    if (isSuccess && data?.accessToken) {
      identifyPosthogUser(data);
      dispatch(setAccessToken({ accessToken: data.accessToken }));
    }
  }, [data]);

  return (
    <>
      <LoadingOverlay visible={isLoading || isLoadingPasswordRecovery} />
      {error && 'data' in error && <Error message={error.data as string} isOpen={isError} />}
      {errorPasswordRecovery && 'data' in errorPasswordRecovery && (
        <Error message={errorPasswordRecovery.data as string} isOpen={isErrorPasswordRecovery} />
      )}
      {isRedirectFromUniqueFlow && (
        <InfoDialog
          header="Vimos que ya habias creado una cuenta con ese rut"
          msg="Inicia sesión para seguir con el flujo"
        />
      )}
      <Grid className={styles.MainGridContainer} container component="main">
        <Grid item xs={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', lg: '60%' },
              mx: 'auto',
              height: '100vh',
              alignItems: 'center',
              pt: '60px',
            }}
          >
            {passwordReset && <CloseableAlert msg="Se ha cambiado tu contraseña exitosamente" severity="success" />}
            <img alt="Relif" src="/logo.webp" height="50" />
            <Typography variant="h4" sx={{ mt: 3 }}>
              Iniciar Sesión
            </Typography>
            <Box
              sx={{
                bgcolor: 'backgroundGrey.main',
                p: 2,
                borderRadius: '20px',
                mt: 2,
                border: '1px solid hsla(210,18%,87%,1)',
                width: { xs: '88%', lg: '420px' },
              }}
              component="form"
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                handleSubmit(e).catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                });
              }}
            >
              {!signInWithRut && (
                <TextField
                  id="email"
                  margin="normal"
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{ bgcolor: '#fff' }}
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                  }}
                  error={noEmailError || invalidEmail}
                  helperText={emailInputHelperText()}
                />
              )}
              {signInWithRut && (
                <TextField
                  id="rut"
                  name="rut"
                  autoComplete="rut"
                  value={rut}
                  required
                  fullWidth
                  label="Rut"
                  sx={{ bgcolor: '#fff' }}
                  onChange={(e) => {
                    setRut(format(e.target.value));
                  }}
                />
              )}
              <TextField
                id="password"
                margin="normal"
                required
                fullWidth
                label="Contraseña"
                name="password"
                type="password"
                autoComplete="password"
                sx={{ bgcolor: '#fff' }}
              />
              <SecondaryBtn sx={{ mt: 2, py: 1, mb: 1 }} type="submit" fullWidth variant="contained" color="secondary">
                Iniciar sesión
              </SecondaryBtn>
            </Box>

            <Typography
              variant="h6"
              color="initial"
              sx={{
                width: { xs: '88%', lg: '420px' },
                py: 2,
                textAlign: 'center',
                border: '1px solid #d0d7de',
                borderRadius: '10px',
                mt: 2,
                fontWeight: '400',
              }}
            >
              No tienes cuenta?
              <Link className={styles.LinkToSignUp} to="/sign-up">
                Registrate acá
              </Link>
            </Typography>
            <Typography
              variant="h6"
              color="initial"
              sx={{
                width: { xs: '88%', lg: '420px' },
                py: 2,
                textAlign: 'center',
                border: '1px solid #d0d7de',
                borderRadius: '10px',
                mt: 2,
                fontWeight: '400',
              }}
              onClick={handlePasswordRecovery}
              className={styles.RecoverPassword}
            >
              Olvidé mi contraseña
            </Typography>
            {!signInWithRut && (
              <Typography
                variant="h6"
                color="initial"
                sx={{
                  width: { xs: '88%', lg: '420px' },
                  py: 2,
                  textAlign: 'center',
                  border: '1px solid #d0d7de',
                  borderRadius: '10px',
                  mt: 2,
                  fontWeight: '400',
                }}
                onClick={() => {
                  setSignInWithRut(true);
                }}
                className={styles.RecoverPassword}
              >
                Iniciar sesión con rut
              </Typography>
            )}
            {signInWithRut && (
              <Typography
                variant="h6"
                color="initial"
                sx={{
                  width: { xs: '88%', lg: '420px' },
                  py: 2,
                  textAlign: 'center',
                  border: '1px solid #d0d7de',
                  borderRadius: '10px',
                  mt: 2,
                  fontWeight: '400',
                }}
                onClick={() => {
                  setSignInWithRut(false);
                }}
                className={styles.RecoverPassword}
              >
                Iniciar sesión con email
              </Typography>
            )}
            {dataPasswordRecovery && (
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  py: 2,
                  px: 1,
                  my: 1,
                  textAlign: 'center',
                  fontWeight: '400',
                  width: '100%',
                }}
              >
                {dataPasswordRecovery.message}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
