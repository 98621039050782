import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import useIsMobile from 'hooks/useIsMobile';

import styles from '../BukDashboard.module.scss';

interface Props {
  imgSrc: string;
  alt: string;
  text: string;
}

const ChatButton = ({ imgSrc, alt, text }: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const handleClick = () => {
    navigate(`/chat?firstMessage=${text}`);
  };
  return (
    <Grid item xs={isMobile ? 12 : 6}>
      <Box className={styles.ButtonContainer} onClick={handleClick}>
        <img src={imgSrc} alt={alt} />
      </Box>
    </Grid>
  );
};

export default ChatButton;
