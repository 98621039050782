/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { LockIcon } from 'assets/icons';
import PrimaryBtn, { SecondaryBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import useQuery from 'hooks/useQuery';
import Error from 'pages/Error/ErrorAlert';
import { FeaturesData } from 'pages/LandingPageNew/data';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { format } from 'rut.js';
import { useAddToWaitListMutation, useSignInUserMutation, useSignUpUserMutation } from 'services/api/user.api';
import getReCaptchaToken from 'utils/getReCaptchaToken';
import { identifyPosthogUser } from 'utils/posthogUtils';
import validateEmail from 'utils/validations/validateEmail';
import validatePassword, { getPasswordErrorMessage } from 'utils/validations/validatePassword';

import styles from './SignUp.module.scss';
import ChooseRegister from './components/ChooseRegister';

const SignUp: FC = () => {
  const query = useQuery();
  const useRut = query.get('useRut'); // t: true, f: false, null: not defined

  const firstName = '';
  const lastName = '';
  const [phone, setPhone] = useState('569');
  const [email, setEmail] = useState('');
  const [rut, setRut] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [rutError, setRutError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [waitListOpen, setWaitListOpen] = useState(false);
  const [chooseRegisterOpen, setChooseRegisterOpen] = useState(useRut !== 't' && useRut !== 'f');
  const [rutRegisterOpen, setRutRegisterOpen] = useState(useRut === 't');
  const [mailRegisterOpen, setMailRegisterOpen] = useState(useRut === 'f');
  const [isSignUp, setIsSignUp] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [signUp, { isLoading: signUpLoading, isError, error }] = useSignUpUserMutation();
  const [signIn, { data: signInData, isLoading: signInLoading, isSuccess }] = useSignInUserMutation();
  const [
    addToWaitList,
    { isLoading: waitListLoading, isError: isWaitListError, isSuccess: isWaitListSuccess, error: waitListError },
  ] = useAddToWaitListMutation();
  const validatePhone = (value: string) => value.startsWith('569') && value.length === 11;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && signInData) {
      identifyPosthogUser(signInData);
      dispatch(setAccessToken({ accessToken: signInData.accessToken }));
    }
  }, [signInData]);

  const SignIn = async () => {
    const recaptchaToken = await getReCaptchaToken();
    await signIn({
      recaptchaToken,
      email: email.toString(),
      password: password.toString(),
      rut: rut.toString(),
    });
  };

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const response = (await signUp({
      firstName,
      lastName,
      email,
      rut,
      password,
      phone,
      referrer: query.get('referrer') ? Number(query.get('referrer')) : undefined,
    })) as { error?: object };
    if (!response.error) {
      setIsSignUp(true);
      await SignIn();
    }
  };

  const handleWaitListSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await addToWaitList({
      email,
      firstName,
      lastName,
      phone,
      companyName,
    });
  };

  const emailVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setEmailError(false);
    } else {
      setEmailError(!validateEmail(event.target.value));
    }
  };

  const rutFormatter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRut(format(event.target.value));
    if (!event.target.value) {
      setRutError(false);
    } else {
      setRutError(!format(event.target.value));
    }
  };

  const passwordVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setPasswordError(false);
    } else {
      setPasswordError(!validatePassword(event.target.value, rut, email));
    }
  };

  const passwordConfirmVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
    if (!event.target.value) {
      setPasswordConfirmError(false);
    } else {
      setPasswordConfirmError(password !== event.target.value);
    }
  };

  const verifyForm = () => {
    const values = (!!rut || !!email) && !!validatePhone(phone) && !!password && !!passwordConfirm;
    const errors = emailError || passwordError || passwordConfirmError || rutError;
    return values && !errors;
  };

  useEffect(() => {
    if (isError) {
      if ((error as FetchBaseQueryError)?.status === 403) {
        setWaitListOpen(true);
      }
    }
  }, [isError]);

  if (waitListOpen) {
    return (
      <div className={styles.MainContainer}>
        <Box
          className={styles.MainBoxContainer}
          component="form"
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            handleWaitListSubmit(e).catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
          }}
        >
          {/* @ts-ignore */}
          <Error message={waitListError?.data} isOpen={isWaitListError} />

          <LoadingOverlay visible={waitListLoading} />
          <Link to="/">
            <img className={styles.mobileLogo} alt="Relif" src="/logo.webp" />
          </Link>
          <Avatar className={styles.LockAvatarIcon}>
            <FormatListNumbered />
          </Avatar>
          <Typography variant="h4" sx={{ mb: '0.8rem' }}>
            Unirse a la lista de espera
          </Typography>
          <Typography variant="body1" sx={{ mb: '1.5rem' }}>
            Aún no estamos disponibles para trabajadores de tu empresa. Pero, puedes unirte a la lista de espera para
            que te avisemos cuando estemos listos. Confirma tu correo y el nombre de tu empresa.
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={emailVerify}
            error={emailError}
            helperText={emailError ? 'Correo electrónico inválido' : ''}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="companyName"
            label="Nombre de la empresa"
            name="companyName"
            autoComplete="companyName"
            autoFocus
            value={companyName}
            onChange={(event) => setCompanyName(event.target.value)}
          />
          {!isWaitListSuccess && (
            <>
              <SecondaryBtn type="submit" fullWidth sx={{ mt: 3, mb: 2 }} disabled={emailError || !email}>
                Unirse a la lista de espera
              </SecondaryBtn>
              <PrimaryBtn
                fullWidth
                sx={{ mb: 2 }}
                onClick={() => {
                  setWaitListOpen(false);
                }}
              >
                Probar con otro correo
              </PrimaryBtn>
            </>
          )}
          {isWaitListSuccess && (
            <>
              <Typography variant="body1" sx={{ mb: '1.5rem' }}>
                ¡Gracias por unirte a la lista de espera! Te avisaremos cuando estemos listos.
              </Typography>
              <PrimaryBtn fullWidth sx={{ mb: 2 }} onClick={() => navigate('/')}>
                Ir a la página de inicio
              </PrimaryBtn>
            </>
          )}
        </Box>
        <Box className={styles.DescriptionContainer}>
          <img className={styles.logoSignUp} alt="Relif" src="/logo.webp" />
          <Typography variant="h2" color="white" sx={{ fontWeight: '500' }}>
            Empieza a tomar el control de tus finanzas
          </Typography>
          <div>
            {FeaturesData.features.map((feature, index) => (
              <div className={styles.SignUpBenefit} key={`f-${index + 1}`}>
                <div className={styles.signUpFeatures}>
                  <feature.icon />
                  <h3>{feature.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </div>
    );
  }

  const phoneInput = (
    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
      <Typography variant="body1" mr={2}>
        Celular:
      </Typography>
      <PhoneInput
        country="cl"
        placeholder="Celular"
        value={phone}
        onChange={(value) => {
          setPhone(value);
        }}
        inputStyle={{
          width: '100%',
          height: '55px',
          font: 'inherit',
          paddingLeft: '48px',
          borderRadius: '3px',
          color: 'black',
        }}
        containerClass={styles.BorderClass}
        isValid={() => {
          if (phone.length === 3 || validatePhone(phone)) {
            return true;
          }
          if (!phone.startsWith('569')) {
            return 'Número debe comenzar con +56 9';
          }
          return 'Largo de número incorrecto';
        }}
      />
    </Grid>
  );

  return (
    <div className={styles.MainContainer}>
      <LoadingOverlay visible={signInLoading || signUpLoading} />
      {/* @ts-ignore */}
      <Error message={error?.data} isOpen={isError && error?.status !== 403} />
      <Box className={styles.MainBoxContainer}>
        <Link to="/">
          <img className={styles.mobileLogo} alt="Relif" src="/logo.webp" />
        </Link>
        {!chooseRegisterOpen && !isSignUp && (
          <>
            <Avatar className={styles.LockAvatarIcon}>
              <LockIcon />
            </Avatar>
            <Typography variant="h4" sx={{ mb: '0.8rem' }}>
              Registrar
            </Typography>
          </>
        )}
        {chooseRegisterOpen && (
          <ChooseRegister
            setRutRegisterOpen={setRutRegisterOpen}
            setMailRegisterOpen={setMailRegisterOpen}
            setChooseRegisterOpen={setChooseRegisterOpen}
          />
        )}
        <Box
          className={styles.FormBox}
          component="form"
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            handleSignUp(e).catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
          }}
        >
          <Grid container spacing={2}>
            {mailRegisterOpen && !isSignUp && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    autoComplete="email"
                    required
                    fullWidth
                    label="Email"
                    onChange={emailVerify}
                    error={emailError}
                    helperText={emailError && 'Debe ser un correo'}
                    sx={{ bgcolor: '#fff' }}
                  />
                </Grid>
                {phoneInput}
              </>
            )}
            {rutRegisterOpen && !isSignUp && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="rut"
                    name="rut"
                    autoComplete="rut"
                    value={rut}
                    required
                    fullWidth
                    label="Rut"
                    onChange={rutFormatter}
                    error={rutError}
                    sx={{ bgcolor: '#fff' }}
                  />
                </Grid>
                {phoneInput}
              </>
            )}
            {!chooseRegisterOpen && !isSignUp && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="password"
                    name="password"
                    autoComplete="new-password"
                    required
                    fullWidth
                    label="Contraseña"
                    type="password"
                    onChange={passwordVerify}
                    error={passwordError}
                    helperText={passwordError && getPasswordErrorMessage(password, rut, email)}
                    sx={{ bgcolor: '#fff' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="passwordConfirm"
                    name="passwordConfirm"
                    required
                    fullWidth
                    label="Confirmar contraseña"
                    type="password"
                    onChange={passwordConfirmVerify}
                    error={passwordConfirmError}
                    helperText={passwordConfirmError && 'Las contraseñas no coinciden'}
                    sx={{ bgcolor: '#fff' }}
                  />
                </Grid>
                <PrimaryBtn
                  className={styles.SubmitButton}
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!verifyForm()}
                >
                  Registrar
                </PrimaryBtn>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="body1" color="initial">
                    ¿Ya tienes cuenta?
                    <Link to="/sign-in" className={styles.SingInLink}>
                      Inicia sesión
                    </Link>
                  </Typography>
                  <Typography
                    variant="body1"
                    color="initial"
                    onClick={() => {
                      setChooseRegisterOpen(true);
                      setRutRegisterOpen(false);
                      setMailRegisterOpen(false);
                    }}
                    className={styles.SingInLink}
                  >
                    Volver
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </Box>
      <Box className={styles.DescriptionContainer}>
        <img className={styles.logoSignUp} alt="Relif" src="/logo.webp" />
        <Typography variant="h2" color="white" sx={{ fontWeight: '500' }}>
          Empieza a tomar el control de tus finanzas
        </Typography>
        <div>
          {FeaturesData.features.map((feature, index) => (
            <div className={styles.SignUpBenefit} key={`f-${index + 1}`}>
              <div className={styles.signUpFeatures}>
                <feature.icon />
                <h3>{feature.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default SignUp;
