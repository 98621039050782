/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// @ts-ignore
import { Checkbox } from '@dscla/ds-checkbox/react';
// @ts-ignore
import { Input } from '@dscla/ds-input/react';
import documentNumberImage from 'assets/images/numero_de_documento.webp';
import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import useCurrentRoute from 'hooks/useCurrentRoute';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { Origin } from 'services/types/origin';
import trpc from 'trpc';
import { identifyPosthogUser } from 'utils/posthogUtils';

import styles from '../UniqueDebtFlow.module.scss';
import ModalWithHeader from './ModalWithHeader';

interface Props {
  onSuccess: () => void;
  origin: Origin;
  rut: string;
  phone: string;
  email: string;
  externalProviderId?: string;
}

const SignMandateAndCreateUser: FC<Props> = ({ onSuccess, origin, rut, phone, email, externalProviderId }) => {
  const [documentNumber, setDocumentNumber] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  let { companyName } = useParams<{ companyName: string }>();

  const [msgError, setMsgError] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openAuthErrorDialog, setOpenAuthErrorDialog] = useState(false);
  const { path } = useCurrentRoute();
  const dispatch = useDispatch();
  const validateDocumentNumberAndSignIn = trpc.uniqueFlowUsers.validateDocumentNumberAndSignIn.useMutation();

  if (!companyName) {
    if (path.includes('iansa')) {
      companyName = 'Iansa';
    } else if (path.includes('banco-chile')) {
      companyName = 'Banco de Chile';
    } else {
      companyName = 'Caja Los Andes';
    }
  }
  const documentNumberFormatter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatter = new Intl.NumberFormat('es-CL');
    const value = Number(e.target.value.replace(/\D+/g, '').slice(0, 9));
    setDocumentNumber(formatter.format(value));
  };

  const validate = () => {
    return documentNumber.length >= 11 && acceptedTerms;
  };

  useEffect(() => {
    if (validateDocumentNumberAndSignIn.isSuccess) {
      // Check messages, and that everything is ok
      if (validateDocumentNumberAndSignIn.data.signInResponse?.accessToken !== undefined) {
        identifyPosthogUser(validateDocumentNumberAndSignIn.data.signInResponse);
        dispatch(setAccessToken({ accessToken: validateDocumentNumberAndSignIn.data.signInResponse.accessToken }));
        onSuccess();
      }
      const { message } = validateDocumentNumberAndSignIn.data;
      if (message.includes('no está vigente') || message.includes('documento inválido')) {
        setOpenAuthErrorDialog(true);
      }
      if (message.includes('Lo sentimos')) {
        setMsgError(message);
        setOpenErrorDialog(true);
      }
    }
    if (validateDocumentNumberAndSignIn.isError) {
      setOpenErrorDialog(true);
    }
  }, [validateDocumentNumberAndSignIn.isSuccess, validateDocumentNumberAndSignIn.isError]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      validateDocumentNumberAndSignIn.mutate({ rut, documentNumber, phone, email, externalProviderId });
    }
  };

  return (
    <ModalWithHeader origin={origin} title="Ingresa tu número de documento">
      <LoadingOverlay visible={validateDocumentNumberAndSignIn.isLoading} />
      <AlertDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        header="Hubo un error, si el problema persiste envia una foto de tu carnet al chat"
        msg={msgError || 'Perdón por las molestias'}
        confirmMsg="Cerrar"
        confirmAction={() => setOpenErrorDialog(false)}
        cancelMsg=""
      />
      <AlertDialog
        openDialog={openAuthErrorDialog}
        setOpenDialog={setOpenAuthErrorDialog}
        header="Error al autentificarte"
        msg="Tu RUT no coincide con el número de documento que has ingresado. Si el problema persiste, por favor envíanos una foto de tu carnet al chat"
        confirmMsg="Cerrar"
        confirmAction={() => setOpenAuthErrorDialog(false)}
        cancelMsg=""
      />
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(e);
        }}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box className={styles.ContentBox}>
          {/* TODO: only allow number input */}
          <Input
            id="documentNumber"
            name="documentNumber"
            type="text"
            placeholder="Ej: 123.456.789"
            value={documentNumber}
            maxLength={11}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => documentNumberFormatter(e)}
          />
          <Typography variant="body1" width="100%" textAlign="left" pt={1} pb={2}>
            Necesitamos este número para validar tu identidad y confirmar que eres tú quien se está inscribiendo ¡Nunca
            Lo guardaremos!
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={documentNumberImage} alt="Número de documento" width={300} />
            <Typography variant="body1" fontSize="18px" />
            <FormControlLabel
              control={<Checkbox checked={acceptedTerms} />}
              onChange={(_, checked) => {
                setAcceptedTerms(checked);
              }}
              sx={{ mt: 1 }}
              label={
                <Typography variant="body1" fontSize="18px">
                  Acepto{' '}
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    href="https://storage.googleapis.com/relif-public-bucket/Poder%20Especial.pdf"
                  >
                    Poder
                  </Link>{' '}
                  y{' '}
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    href="https://storage.googleapis.com/relif-public-bucket/terms_and_conditions.pdf"
                  >
                    Términos & Condiciones
                  </Link>
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box display="grid" justifyContent="center" alignItems="center" py={3}>
          <PrimaryBtn type="submit" disabled={!validate()}>
            Continuar
          </PrimaryBtn>
        </Box>
      </Box>
    </ModalWithHeader>
  );
};

SignMandateAndCreateUser.defaultProps = {
  externalProviderId: undefined,
};

export default SignMandateAndCreateUser;
