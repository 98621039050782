import { Message } from '../types/whatsApp';
import baseApi from './baseApi';

const publicAccessApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserChatIdentifier: build.query({
      query: () => ({
        url: '/chat/pusher-identifier',
        method: 'GET',
      }),
      transformResponse: (response: { pusherIdentifier: string }) => response.pusherIdentifier,
    }),
    getChatUserMessages: build.query({
      query: () => ({
        url: `/chat/messages`,
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
    createChatMessage: build.mutation({
      query: (props: { message: string }) => ({
        url: '/chat/messages',
        method: 'POST',
        body: props,
      }),
      transformResponse: (response: { status: string }) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserChatIdentifierQuery, useGetChatUserMessagesQuery, useCreateChatMessageMutation } =
  publicAccessApi;
