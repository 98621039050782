import { LinkProps } from '@mui/material/Link';
import { createTheme } from '@mui/material/styles';

import LinkBehavior from '../LinkBehaviour';

// Created using: https://www.figma.com/file/H8ez3YtwWWprvuqeEAf73F/INTRODUCTION-%26-FOUNDATION?node-id=48570:146735&mode=dev
const cajaLosAndesTheme = createTheme({
  palette: {
    primary: {
      main: '#0076A9', // C1 Primary
      contrastText: '#fff',
    },
    secondary: {
      main: '#F9BE00', // A3
      light: '#FDE1A7', // A5
      contrastText: '#000',
    },
    newBlue: {
      A100: '#6293C8',
      A400: '#2A578B',
      A700: '#00648F', // C0
    },
    success: {
      main: '#008744', // V1
      light: '#DEFFE8', // V6
    },
    error: {
      main: '#DB1C10', // R2
      light: '#FFE1DE', // R6
    },
    warning: {
      main: '#B34A00', // N1
      light: '#FFECDE', // N6
    },
    text: {
      primary: '#000',
    },
    info: {
      main: '#4EBCE3', // C4
      light: '#DEF4FF', // C6
      contrastText: '#fff',
    },
    backgroundGrey: {
      main: '#F6F7F7', // G6
    },
  },
  typography: {
    fontFamily: "'Rawson Pro', 'Jost','sans-serif'",
    h1: {
      fontSize: '50px',
      fontWeight: 700,
      color: 'white',
      lineHeight: '60px',
    },
    h2: {
      fontSize: '22px',
      fontWeight: 500,
      color: '#000',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '20px',
      fontWeight: 650,
      color: '#000',
      lineHeight: '32px',
    },
    h4: {
      fontSize: '19px',
      fontWeight: 400,
      color: '#000',
      lineHeight: '30px',
    },
    h5: {
      fontSize: '17px',
      fontWeight: 400,
      color: '#A6A7A8',
      lineHeight: '24px',
    },
    body1: {
      fontSize: '18px',
      color: '#000',
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      fontSize: '16px',
      color: '#4EBCE3',
      fontWeight: 650,
      lineHeight: '20px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1800,
    },
  },
  components: {
    // Name of the component
    MuiPaper: {
      defaultProps: {
        // The props to change the default for.
        elevation: 0, // No elevation for paper!
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: 'green',
          color: 'white',
        },
        standardError: {
          backgroundColor: 'red',
          color: 'white',
        },
        standardWarning: {
          backgroundColor: 'rgb(255, 244, 229)',
          color: 'rgb(102, 60, 0)',
          '& .MuiAlert-icon': {
            color: '#ff9800',
          },
          marginBottom: 2,
        },
        standardInfo: {
          backgroundColor: 'grey',
          color: 'black',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
  },
});

export default cajaLosAndesTheme;
